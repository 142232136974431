import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  viewChild,
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StripeCreditCardComponent } from '../../checkout/stripe-credit-card/stripe-credit-card.component';
import { EventDirective } from '../../event.directive';
import { PaymentMethodService } from '../../payment-method.service';
import { StoreContextService } from '../../store-context.service';

@Component({
    selector: 'app-payment-options',
    imports: [
        NgOptimizedImage,
        ReactiveFormsModule,
        StripeCreditCardComponent,
        EventDirective,
    ],
    templateUrl: './payment-options.component.html',
    styleUrl: './payment-options.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentOptionsComponent implements OnInit {
  stripeCreditCard = viewChild(StripeCreditCardComponent);

  paymentMethodControl = input.required<FormControl<string | null>>();

  paymentMethod = inject(PaymentMethodService);
  destroyRef = inject(DestroyRef);
  paymentMethods = this.paymentMethod.paymentMethods;

  storeContext = inject(StoreContextService);
  selectedPaymentMethod = this.storeContext.selectedPaymentMethod;

  ngOnInit() {
    this.paymentMethodControl()
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value) {
          this.storeContext.setPaymentMethod(value);
        }
      });
  }

  async handlePayment() {
    const paymentMethod = this.selectedPaymentMethod();
    if (!paymentMethod) {
      return;
    }

    if (
      paymentMethod.shortName === 'stripe.shopware_payment.payment_handler.card'
    ) {
      const card = this.stripeCreditCard();
      const paymentMethod = await card?.getCard();
      if (!paymentMethod) {
        return;
      }
    }

    // if (paymentMethod.shortName === 'stripe.shopware_payment.payment_handler.digital_wallets') {
    //
    // }
  }
}
