import { inject, Injectable, makeStateKey } from '@angular/core';
import { StoreApiService } from './store-api.service';
import { ListState } from './listStore';
import { components, Schemas } from '../api-types/storeApiTypes';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  storeApi = inject(StoreApiService);

  paymentMethods = this.storeApi.createListStore(
    makeStateKey<ListState<Schemas['PaymentMethod']>>('payment-methods'),
    () =>
      this.storeApi.apiClient
        .invoke('readPaymentMethod post /payment-method', {
          body: {},
        })
        .then((r) => {
          return {
            elements: this.sortPaymentMethods(r.data.elements ?? []),
            total: r.data.total ?? 0,
          };
        }),
  );

  private sortPaymentMethods(a: components['schemas']['PaymentMethod'][]) {
    return a.sort((a, b) => {
      if (a.position === b.position) {
        return a.name!.localeCompare(b.name!);
      }
      return a.position ?? 0 - (b.position ?? 0);
    });
  }
}
