<div class="grid grid-cols-1 md:grid-cols-2 gap-y-2">
  @if (paymentMethods(); as paymentState) {
    @if (!paymentState.loading) {
      @for (payment of paymentState.elements; track payment.id) {
        <div class="flex items-center">
          <input
            [attr.id]="'payment-' + payment.id"
            type="radio"
            name="paymentMethodId"
            [formControl]="paymentMethodControl()"
            [value]="payment.id"
            [appEvent]="'select-payment'"
            [appEventData]="{
              id: payment.id,
              name: payment.translated!['name'],
            }"
            class="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
          />

          <label
            [attr.for]="'payment-' + payment.id"
            class="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >{{ payment.translated!["name"] }}</label
          >
          @if (payment.media) {
            <img
              [ngSrc]="payment.media.url"
              [alt]="payment.name"
              width="40"
              height="20"
              class="mx-2"
            />
          }
        </div>
      }
    }
  }
</div>

@if (
  selectedPaymentMethod()?.shortName ===
  "stripe.shopware_payment.payment_handler.card"
) {
  <app-stripe-credit-card></app-stripe-credit-card>
}
