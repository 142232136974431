import { computed, inject, Injectable, signal } from '@angular/core';
import { StoreApiService } from './store-api.service';
import { PaymentMethodService } from './payment-method.service';

@Injectable({
  providedIn: 'root',
})
export class StoreContextService {
  storeApi = inject(StoreApiService);
  paymentMethod = inject(PaymentMethodService);

  selectedPaymentMethodId = signal<string | null>(null);

  selectedPaymentMethod = computed(() => {
    const paymentMethods = this.paymentMethod.paymentMethods();
    if (paymentMethods.loading) {
      return null;
    }
    return (
      paymentMethods.elements.find(
        (p) => p.id === this.selectedPaymentMethodId(),
      ) ?? null
    );
  });

  constructor() {
    this.storeApi.apiClient.invoke('readContext get /context').then((res) => {
      if (res.data.paymentMethod) {
        this.selectedPaymentMethodId.set(res.data.paymentMethod.id);
      }
    });
  }

  async setPaymentMethod(id: string) {
    if (this.selectedPaymentMethodId() !== id) {
      this.selectedPaymentMethodId.set(id);
      await this.storeApi.apiClient.invoke('updateContext patch /context', {
        body: {
          paymentMethodId: id,
        },
      });
    }
  }
}
