import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { StripeCardElement } from '@stripe/stripe-js';

@Component({
    selector: 'app-stripe-credit-card',
    imports: [ReactiveFormsModule],
    templateUrl: './stripe-credit-card.component.html',
    styleUrl: './stripe-credit-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StripeCreditCardComponent implements AfterViewInit {
  elm = inject(ElementRef);

  stripePromise = import('@stripe/stripe-js').then((s) =>
    s.loadStripe(environment.stripePublicKey),
  );
  card?: StripeCardElement;

  async ngAfterViewInit() {
    const stripe = await this.stripePromise;
    if (!stripe) {
      return;
    }

    const elements = stripe.elements();
    const card = elements.create('card', {
      disableLink: true,
      hidePostalCode: true,
    });

    card.mount(this.elm.nativeElement.querySelector('#card')!);
    this.card = card;
  }

  async getCard() {
    const stripe = await this.stripePromise;
    if (!stripe) {
      return;
    }

    if (!this.card) {
      return;
    }

    const paymentRequest = await stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: { name: 'Max' },
    });

    document.cookie =
      'card=' +
      JSON.stringify({ card: { id: paymentRequest.paymentMethod?.id } }) +
      ';path=/';

    return paymentRequest.paymentMethod;
  }
}
