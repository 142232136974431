<label for="form-field-creditcard"
       class="block text-sm font-medium leading-6 text-gray-900">Kreditkarte <span class="text-red-600">*</span></label>
<div class="mt-2">
  <div id="form-field-creditcard"
         class="w-full bg-white rounded-md border-0 px-2 h-10 flex items-center py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
    <div id="card" class="flex-grow min-w-0">
    </div>
  </div>
</div>


